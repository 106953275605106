import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { CssBaseline, Box } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { PageContainer, ContentContainer } from '@system'

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    padding: '50px 0px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  outerContainer: {
    display: 'flex',
  },
}))

const TrialProductsModule = (props) => {
  const { productCards } = props.blok

  const classes = useStyles(props.blok)

  return (
    <SbEditable content={props.blok}>
      <CssBaseline />
      <PageContainer>
        <ContentContainer className={classes.outerContainer}>
          {!!productCards && (
            <Box className={classes.innerContainer}>
              {renderBloks(productCards)}
            </Box>
          )}
        </ContentContainer>
      </PageContainer>
    </SbEditable>
  )
}

export default TrialProductsModule
